/* Resource Page */

.row-general{
    height: 230px;
    text-align: center;
}

.column-container{
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
}

.column{
    padding: 20px;
    text-align: center;
    float: left;
    border: 5px solid var(--red);
}

.section-title {
    color: var(--red);
    margin: 10px ;
    margin-bottom: 40px;
    font-size: 25px;
}

.button-link{
    height: 100px;
    width: 70%;
    color: white;
    background-color: var(--red);
    font-size: 2.9vmin;
    border: 0 !important;
    margin-bottom: 40px;
    padding: 10px;
}
.button-link:hover{
    background-color: var(--gold);
    color:white;
}

.row-button-link{
    height: 100px;
    width: 100%;
    background-color: var(--red);
    color: white;
    font-size: 3.2vmin;
    border: 0 !important;
    padding:10px;
}
.row-button-link:hover{
    background-color: var(--gold);
    color: white;
}
.button-row{
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.button-row a{
    width: 25%;
}

.row{
    width: 100%;
}
/* Contact Us */

.title-container {
    height: 100px;
    width: 100%;
    text-align: center;
    padding: 1em 0em 0em 1em
}

.title-name {
    font-size: 40px;
    color: var(--blue);
}

.grid-container{
    height: 500px;
    width: 85%;
    margin: auto;
    justify-content: center;
}

.contact-card {
    background-color: var(--red);
    color: white;
    height: 100px;
    width: 100%;
    margin: auto;
    border: 0 !important;
    text-align: center;
    align-items: center;
}
.contact-card:hover {
    background-color: var(--gold);
}

.email-font{
    font-size: 20px;
}
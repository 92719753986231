.landing-banner {
    background-image: url("../../assets/images/home_banner.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 70vmin;
    display: flex;
    align-items: flex-end;
}

.landing-greeting {
    margin-left: auto;
    margin-right: auto;
    min-width: 60%;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    gap: 1.5em;
    padding: 1.5em;
    font-weight: 200;
}

.landing-greeting .sub-text {
    font-size: 1.7vmax;
    text-align: center;
}

.landing-greeting .header-text {
    font-size: 3vmax;
    font-weight: normal;
    text-align: center;
}

.landing-btn {
    font-weight: bold;
    width: 25%;
}

.landing-level{
    max-width: 80%;
    width: 80%;
    display: flex;
    justify-content: center;
    margin: 4rem auto;
}

.landing-level .row>*, .sponsor-item{
    border: 1px solid hsl(var(--black-h),var(--black-s), calc(var(--black-l) + 85%));
}

.level-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    width: 100%;
    height: 100%;
    text-align: center;
    gap: 2.5rem;
}

.landing-title{
    text-transform: uppercase;
    font-size: 2.5vmax;
}

.landing-content{
    line-height: 190%;
    font-weight: 200;
}

.landing-content.about{
    width: 50%;
}

.landing-about{
    background-image: linear-gradient(hsla(var(--red-h), var(--red-s), var(--red-l), 0.85), hsla(var(--red-h), var(--red-s), var(--red-l), 0.85)),url("../../assets/images/home_banner.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll, fixed;
    min-height: 60vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    gap: 2.5rem;
    padding: 7rem 0;
}

.landing-grants{
    background-color: hsl(0, 0%, 97%);
    min-height: 60vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2.5rem;
    padding: 7rem 0;
}

.landing-sponsors{
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2.5rem;
}

.sponsor-container{
    max-width: 100%;
}

.sponsor-item{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    text-align: center;
    max-height: 10rem;
}

.sponsor-item img.tall{
    max-height: 100%;
    width: 100%;
}

.sponsor-item img.wide{
    height: 100%;
    max-width: 100%;
}

.shrink-btn{
    padding: 1.2vmax;
    font-size: 1.2vmax;
}
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;400;700&family=Work+Sans:wght@200;400;600&display=swap');

:root {
    --red-h: 338;
    --blue-h: 214;
    --gold-h: 39;
    --black-h: 0;
    --red-s: 64%;
    --blue-s: 56%;
    --gold-s: 70%;
    --black-s: 0%;
    --red-l: 34%;
    --blue-l: 22%;
    --gold-l: 51%;
    --black-l: 0%;
    --red: hsl(var(--red-h), var(--red-s), var(--red-l));
    --blue: hsl(var(--blue-h), var(--blue-s), var(--blue-l));
    --gold: hsl(var(--gold-h), var(--gold-s), var(--gold-l));
    --black: hsl(var(--black-h), var(--black-s), var(--black-l));
    --footer-height: 175px;
}

*, *::after, *::before {
    box-sizing: border-box;
}

body {
    font-family: 'Noto Sans' !important;
    min-height: 100vh;
}

.main {
    height: 100%
}

/* navbar formatting and code */

.navbar {
    position: sticky !important;
    top: 0;
    background-color: var(--red);
    padding: 0em 0em 0em 0em;
    width: 100%;
    color: white;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -0.5px 0 rgb(0 0 0 / 15%);
}

.nav-link {
    font-size: 25px !important;
    color: var(--white) !important;
    padding-right: 30px !important;
    text-decoration: none;
    text-align: center;
}

.nav-link:hover {
    color: var(--gold) !important;
    text-decoration: none;
}

.nav-link:visited {
    text-decoration: none;
}

.nav-img {
    height: 72px;
    width: 72px;
    float: left;
    margin-right: 30px;
    border-radius: 50%;
}

/* Home Page */

.btn {
    border-radius: 0 !important;
}

.btn-primary {
    background-color: hsl(var(--blue-h), var(--blue-s), calc(var(--blue-l) + 10%)) !important;
}

.btn-primary:hover {
    background-color: var(--blue) !important;
}

.btn-warning {
    background-color: var(--gold) !important;
    border-color: var(--gold) !important;
}

.btn-warning:hover {
    background-color: hsl(var(--gold-h), var(--gold-s), calc(var(--gold-l) - 5%)) !important;
    border-color: hsl(var(--gold-h), var(--gold-s), calc(var(--gold-l) - 5%)) !important;
}

.btn-outline-danger {
    border-color: hsl(var(--red-h), var(--red-s), calc(var(--red-l) + 10%)) !important;
}

.btn-outline-danger:hover {
    background-color: hsl(var(--red-h), var(--red-s), calc(var(--red-l) + 10%)) !important;
}

.page-title {
    text-transform: uppercase;
    margin: 1.5rem !important;
    font-weight: bold !important;
    text-align: center;
}
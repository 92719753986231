/* Footer styling */

.footer{
    background-color: var(--red);
    vertical-align: baseline;
    width: 100%;
    height: var(--footer-height);
}

.footer-container{
    color:white;
    text-align: center;
    font-size: 20px;
    padding: 30px;
}

.footer-link{
    color: var(--gold);
    text-decoration: none;
}
.footer-link:hover{
    color: var(--white)
}
.footer-link:visited{
    text-decoration: none;
}
